<template>
  <div class="p2">授权成功！！！</div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    window.close();
  },
};
</script>
<style lang="scss" scoped></style>
